<template>
  <div class="contact-us-page">
    <page-header :cover="require('../assets/images/contact-page-cover.png')" :title="$t('contactUsLong')"
      :desc="$t('contactSubDesc')" />
    <div class="container">
      <div class="row py-5">
        <div class="col-12 col-md-6">
          <div class="sec-title mb-2">{{ $t("keepintouch") }}</div>
          <p class="sec-desc center m-0 mb-2"> {{ $t("lookForYou") }} </p>
          <ContactInfoBox class="in-contact-us" v-for="(data, dind) in info" :key="dind" :infoData="data"
            :selectOffice="() => false" />
          <!-- <SocialLinks /> -->
        </div>
        <div class="col-12 col-md-6">
          <form @submit.prevent="senMessage" class="mt-3 mt-md-0" action="https://formspree.io/f/myyoogkl"
            method="POST">
            <input type="email" name="email" v-model="formData.email" :placeholder="$t('email')" />
            <input type="text" name="phone" v-model="formData.phone" :placeholder="$t('phone')" />
            <input type="text" name="name" v-model="formData.name" :placeholder="$t('name')" />
            <textarea v-model="formData.message" name="message" :placeholder="$t('msg')"></textarea>
            <button ref="btnSub" class="btn-pri-dark w-100 fw-bold"> {{ $t("sendMsg") }} </button>
          </form>
        </div>
      </div>
    </div>
    <!-- <div class="our-offices">
      <div class="container">
        <div class="sec-title text-uppercase my-4">{{ $t("ourOffices") }}</div>
      </div>
      <div class="container px-0">
        <MapSvg class="d-block mx-auto" />
      </div>
      <div class="container">
        <div class="boxs">
          <ContactInfoBox v-for="(office, offInd) in offices" :key="offInd" :infoData="office"
            :selectOffice="() => false" />
        </div>
      </div>
    </div> -->
  </div>
</template>
<script>
import ContactInfoBox from "../components/misc/ContactInfoBox.vue";
import PageHeader from "../components/misc/PageHeader.vue";
// import SocialLinks from "../components/misc/SocialLinks.vue";
// import MapSvg from "../assets/images/map.svg";
export default {
  components: {
    // MapSvg,
    PageHeader,
    ContactInfoBox,
    // SocialLinks,
  },
  data() {
    return {
      info: [
        {
          title: this.$t("address"),
          info: "Steindamm 77، 20099 Hamburg، المانيا",
          icon: "map-marker-alt",
          link: "mailto:info@optivius.com",
        },
        {
          title: this.$t("email"),
          info: "info@optivius.com",
          icon: "envelope",
          link: "mailto:info@optivius.com",
        },
        {
          title: this.$t("callUs"),
          info: "+49 402 840 7552",
          icon: "phone",
          link: "tel:494028407552",
        },
      ],
      offices: [
        {
          title: this.$t("egypt"),
          flag: require("../assets/images/ar.png"),
          icon: "map-marker-alt",
          color: "#FF4D4D",
          subInfo: {
            icon: "phone",
            info: "+20 01002902504",
            link: "tel:+2001002902504",
          },
        },
        {
          title: this.$t("germany"),
          icon: "map-marker-alt",
          color: "#966DD4",
          flag: require("../assets/images/de.png"),

          subInfo: {
            icon: "phone",
            info: "+49 402 840 7552",
            link: "tel:+494028407552",
          },
        },
        // {
        //   title: this.$t("kuwait"),
        //   icon: "map-marker-alt",
        //   color: "#FF8E9A",
        //   flag: require("../assets/images/ku.png"),
        //   subInfo: {
        //     icon: "phone",
        //     info: "+965 2221 6888",
        //     link: "tel:+96522216888",
        //   },
        //   position: { lat: 29.322318053149882, lng: 47.384919331456885 },
        // },
        // {
        //   title: this.$t("qatar"),
        //   icon: "map-marker-alt",
        //   color: "#FFDD73",
        //   flag: require("../assets/images/qa.png"),
        //   subInfo: {
        //     icon: "phone",
        //     info: "+974 7070 3372",
        //     link: "tel:+97470703372",
        //   },
        //   position: { lat: 29.322318053149882, lng: 47.384919331456885 },
        // },
        {
          title: this.$t("turkey"),
          icon: "map-marker-alt",
          color: "#FF6778",

          flag: require("../assets/images/tr.png"),
          subInfo: {
            icon: "phone",
            info: "+90 534 329 0677",
            link: "tel:+905343290677",
          },
          position: { lat: 29.322318053149882, lng: 47.384919331456885 },
        },
        // {
        //   title: this.$t("saudiArabia"),
        //   icon: "map-marker-alt",
        //   color: "#00C4DF",
        //   flag: require("../assets/images/sa.png"),
        //   subInfo: {
        //     icon: "phone",
        //     info: "+966 55 795 1893",
        //     link: "tel:+966557951893",
        //   },
        //   position: { lat: 29.322318053149882, lng: 47.384919331456885 },
        // },
      ],
      formData: {
        phone: '',
        email: '',
        name: '',
        message: ''
      }
    };
  },
  methods: {
    senMessage(e) {
      const data = new FormData(e.target)
      this.$refs.btnSub.setAttribute('disabled', '')
      this.$refs.btnSub.textContent = this.$t('sendingMessage')
      fetch('https://formspree.io/f/xpznzrjw', {
        method: 'POST',
        body: data,
        headers: {
          Accept: "application/json",
        },
      }).then((res) => {
        if (res.ok) {
          this.$refs.btnSub.textContent = this.$t('msgSent')
          this.formData = {
            phone: '',
            email: '',
            name: '',
            message: ''
          }
          this.$refs.btnSub.removeAttribute('disabled', '')
          setTimeout(() => {
            this.$refs.btnSub.textContent = this.$t('sendMsg')
          }, 3000);
        } else {
          this.$refs.btnSub.removeAttribute('disabled', '')

          this.$refs.btnSub.textContent = this.$t('msgErr')
          setTimeout(() => {
            this.$refs.btnSub.textContent = this.$t('sendMsg')
          }, 3000);
        }
      })
    }
  }
};
</script>
